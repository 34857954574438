export class Operation {
  id: number;
  state: string;
  data_type: string;
  action_type: string;
  data_size: number;
  columns_mapping: any;
  ignore_existing: boolean;
  ident_cols: any;
  creator_id: number;
  author_name: string;
  created_at: string;
  updated_at: string;
  columns_for_mapping: Array<string>;
  possible_enum_cols: any;
  possible_ident_cols: Array<string>;
  ident_parent_cols: any;
  ident_individual_clients: boolean;
  ident_company_clients: boolean;
  ident_none_clients: boolean;
  possible_ident_parent_cols: any;
  data?: string;
  enum_cols: any;
  duration: string;

  get navToRightState(): string {
    if (this.state === 'started' || this.state === 'upload') {
      return `/import/${this.id}/upload`;
    } else if (this.state === 'select_first_row') {
      return `/import/${this.id}/erstezeile`;
    } else if (this.state === 'mapping') {
      return `/import/${this.id}/mapping`;
    } else if (this.state === 'enums_mapping') {
      return `/import/${this.id}/enums_mapping`;
    } else if (this.state === 'select_action_type') {
      return `/import/${this.id}/aktion`;
    } else if (this.state === 'checking') {
      return `/import/${this.id}/check`;
    } else if (this.state === 'ident_parent') {
      return `/import/${this.id}/ident_parent`;
    } else {
      return `/import/${this.id}/landing`;
    }
  }

  fromJson(source: any): Operation {
    Object.assign(this, source);
    return this;
  }
}
